<template>
  <div>
    <div class="page-loader" v-if="!isLoaded">
      <div class="cube cube1" style="background-color: #ff6b6b"></div>
      <div class="cube cube2" style="background-color: #ffe66d"></div>
      <div class="cube cube3" style="background-color: #3f51b5"></div>
      <div class="cube cube4" style="background-color: #4caf50"></div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
 data : () => {
        return {
            isLoaded:false
        }
    },
    mounted(){
        document.onreadystatechange = () => {
            if(document.readyState == "complete"){
                this.isLoaded = true;
            }
        }
    }
};
</script>

<style scoped>
.page-loader {
  position: flex;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
      background-color: white;
  z-index: 9999;
}

.cube {
  width: 20px;
  height: 20px;
  margin: 2px;
  background-color: #4a4a4a;
  animation: cube-animation 1.8s infinite ease-in-out;
}

.cube1 {
  animation-delay: 0.1s;
}

.cube2 {
  animation-delay: 0.2s;
}

.cube3 {
  animation-delay: 0.3s;
}

.cube4 {
  animation-delay: 0.4s;
}

@keyframes cube-animation {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

</style>
