<template>
    <div class="min-h-screen">
        <section class="flex items-start justify-center bg-white mt-16 m-2">
            <div class="grid grid-cols-1 md:grid-cols-4 gap-4">
                <div v-for="post in postsData" :key="post.id" class="col-span-1 md:col-span-1">
                    <div class="font-bold">User Name: {{ post.Name }}</div>
                </div>
            </div>

            <div class="container mx-auto">
                <form>
                    <div class="mb-6 text-xl flex my-6" :class="{ 'text-red-500': isRed }">Certificate Id:</div>
                    <input type="text"
                        class="w-full py-3 px-4 text-lg border rounded-lg bg-gray-100 focus:outline-none focus:border-primary"
                        id="certificateCode" placeholder="Enter the certificate Id" v-model="CertificateId" required />

                    <div class="mt-6 text-center flex items-end justify-end">
                        <button type="submit" class="py-4 px-6 text-lg bg-primary text-white rounded-md hover:bg-primary"
                            @click.prevent="verifyCertificate">
                            Verify Certificate
                        </button>
                    </div>
                </form>

                <div v-if="!isDownloading">

                    <div class="flex flex-col text-start m-2" v-if="isShow">
                        <h1 class="text-primary text-4xl  my-2">Certificate Verification Result:</h1>
                        <h2>
                            Certificate for {{ certificate.UserName }} was successfully verified by Sashiv Technology.<br />
                            Certificate Issued on: {{ certificate.dateOfIssue }}
                        </h2>
                    </div>

                    <div class="mt-6 text-center flex min-[320px]:flex-col lg:flex-row" v-if="isShow">
                        <button type="submit" @click="openDialog('certificate')"
                            class="py-4 px-6 text-lg mx-2 bg-primary text-white rounded-xl my-4 hover:bg-primary">
                            Download Certificate
                        </button>

                        <button type="submit" @click="openDialog('adca')" v-if="showDownloadMarkSheetButton"
                            class="py-4 px-6 mx-2 text-lg bg-primary text-white rounded-xl my-4 hover:bg-primary">
                            Download ADCA MarkSheet
                        </button>
                        <button type="submit" @click="openDialog('typing')" v-if="showDownloadTypingButton"
                            class="py-4 px-6 mx-2 text-lg bg-primary text-white rounded-xl my-4 hover:bg-primary">
                            Download Typing Marksheet
                        </button>
                        <button type="submit" @click="openDialog('tally')" v-if="showDownloadTallyButton"
                            class="py-4 px-6 mx-2 text-lg bg-primary text-white rounded-xl my-4 hover:bg-primary">
                            Download Tally Marksheet
                        </button>
                    </div>
                </div>


                <div v-if="isDownloading" class="w-full flex justify-center">
                    <div
                        class="text-primary mt-4 animate-spin justify-center w-10 h-10 rounded-full  border-2 border-t-primary">

                    </div>
                </div>
            </div>
        </section>

        <div class="w-full h-full flex justify-center" v-if="showDialog">
            <div class="fixed inset-0 flex items-center justify-center z-50">
                <div class="bg-white rounded-lg shadow-lg p-6 w-full max-w-md">
                    <h3 class="text-lg font-semibold mb-4">Enter Father's Name and DOB</h3>
                    <div class="mb-4">
                        <label for="fatherName" class="block text-gray-600">Father's Name:</label>
                        <input type="text" id="fatherName" v-model="fatherName"
                            class="w-full border rounded px-3 py-2 focus:outline-none focus:border-primary" />
                    </div>
                    <div class="mb-4">
                        <label for="dob" class="block text-gray-600">Date of Birth:</label>
                        <input type="date" id="dob" v-model="dob"
                            class="w-full border rounded px-3 py-2 focus:outline-none focus:border-primary" />
                    </div>
                    <div class="flex justify-end">
                        <button @click="this.showDialog = false"
                            class="px-4 py-2 text-white bg-red-500 hover:bg-red-600 rounded mr-2">Cancel</button>
                        <button @click="downloadWithDialog"
                            class="px-4 py-2 text-white bg-primary hover:bg-primary-dark rounded">Download</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import jsPDF from "jspdf";
import axios from "axios";
import QRCode from "qrcode";


export default {


    data() {
        return {

            postsData: "",
            id: "",
            isShow: false,
            isRed: false,
            imageDate: "",
            showDownloadMarkSheetButton: false,
            showDownloadTallyButton: false,
            showDownloadTypingButton: false,
            CertificateId: "",
            isDownloading: false,

            ///dialog 
            showDialog: false,
            fatherName: '',
            dob: '',
            detectBtnClicked: "",

            certificate: {
                UserName: "Rj",
                fatherName: "",
                CertificateId: "",
                course: "",
                duration: "",
                grade: "",
                placeOfIssue: "",
                dateOfIssue: "",
                slNo: "",
                dob:""



            },


            adca: {
                duration: "",
                totalPercentage: "",
                division: "",
                grade: "",
                totalObtainedMarks: "",
                slNo: "",
                marks: {
                    computerFundamentals: "",
                    window: "",
                    ms_office: "",
                    tally: "",
                    typing: "",
                }
            },
            tally: {
                duration: "",
                totalPercentage: "",
                division: "",
                grade: "",
                totalObtainedMarks: "",
                slNo: "",
                marks: {
                    foundationOfAccounting: "",
                    tallyWork: "",
                    advanceTallyPrimeWithGST: "",
                    computerLab: "",
                    projectAndViva: "",
                }
            },
            typing: {
                duration: "",
                totalPercentage: "",
                division: "",
                grade: "",
                speedInHindi: "",
                speedInEnglish: "",
                totalObtainedMarks: "",
                slNo: "",
                marks: {
                    hindi: "",
                    english: "",
                }
            }



        };
    },

    methods: {

        openDialog(e) {
            this.showDialog = true
            this.detectBtnClicked = e
        },

    downloadWithDialog() {
            const trimmedFatherName = this.fatherName.replace(/\s/g, '').toLowerCase();
            const trimmedCertificateFatherName = this.certificate.fatherName.replace(/\s/g, '').toLowerCase();

            // Convert the date of birth to a common format
            const formattedDob = this.dob.split('-').reverse().join('/');

            if (trimmedFatherName == trimmedCertificateFatherName && formattedDob == this.certificate.dob) {
                if (this.detectBtnClicked == "certificate") {
                    this.DownloadCertificate()
                }
                if (this.detectBtnClicked == "adca") {
                    this.DownloadADCAMarkSheet()
                }
                if (this.detectBtnClicked == "typing") {
                    this.DownloadTypingMarkSheet()
                }
                if (this.detectBtnClicked == "tally") {
                    this.DownloadTallyMarkSheet()
                }
            } else {
                alert("Invalid credential")
            }
        },




        async DownloadCertificate() {
            var pdf = new jsPDF({ orientation: "l" });

            const logo = require("../assets/new_certificate.jpg");
            // const font = require("../assets/lum.ttf");


            var imgLogo = new Image();
            imgLogo.src = logo;

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            var Uname = this.certificate.UserName.toUpperCase();
            var fName = this.certificate.fatherName.toUpperCase();

            pdf.addImage(imgLogo, "PNG", 0, 0, width, height);
            pdf.setFontSize(12)
            pdf.text(Uname, width / 2 - 10, 106,);
            pdf.text(fName, width / 2 - 50, 118,);

            // await pdf.addFont(font, "cursive", "normal");
            // pdf.setFont("cursive");

            // pdf.text(this.courseDes, width / 2, 115, { align: 'center', fontSize: 1 });
            pdf.text(this.certificate.course, width / 2, 126, { align: 'center' })


            // pdf.setFont("normal")
            pdf.setFontSize(10)
            // pdf.setFontStyle('bold');
            pdf.text("Sl. No. STM" + this.certificate.slNo, 45, 43);
            pdf.text("Reg. No." + this.certificate.CertificateId, 202, 47);
            pdf.text(this.certificate.duration, width / 2 - 60, 136);
            pdf.text(this.certificate.grade, 230, 136);
            pdf.text(this.certificate.placeOfIssue, 76, 153.3);
            pdf.text(this.certificate.dateOfIssue, 73.5, 162);


            const certificateInfo = `Name: ${this.certificate.UserName}\n Issue Date: ${this.certificate.dateOfIssue}\n Certificate ID: ${this.CertificateId}`;

            const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
                scale: 2,
            });
            this.imageDate = qrCodeImageUrl;

            pdf.addImage(this.imageDate, 230, 53);

            pdf.save(this.certificate.UserName + " certificate.pdf");
        },



        async DownloadADCAMarkSheet() {
            this.isDownloading = true;
            try {


                var pdf = new jsPDF({ orientation: "0" });

                const logo = require("../assets/adca_marksheet.jpg");
                // const font = require("../assets/lum.ttf");


                var imgLogo = new Image();
                imgLogo.src = logo;

                var width = pdf.internal.pageSize.getWidth();
                var height = pdf.internal.pageSize.getHeight();

                var Uname = this.certificate.UserName.toUpperCase();
                pdf.setFontSize(10)
                pdf.addImage(imgLogo, "PNG", 0, 0, width, height);
                pdf.setFontSize(12)
                pdf.text(this.certificate.CertificateId, width / 2 + 5, 71);

                pdf.setFontSize(10)
                pdf.text("Sl. No. STM" + this.adca.slNo, 10, 8);
                pdf.setFontSize(12)
                pdf.text(Uname, width / 2 + 5, 80,);
                pdf.text("Advance Diploma In Computer Application", width / 2 + 5, 89.5)
                pdf.text(this.adca.duration, width / 2 + 5, 98.5);
                pdf.text("Sashiv Technology " + this.certificate.placeOfIssue, width / 2 + 5, 108);
                pdf.text(this.certificate.dateOfIssue, width / 2 + 26, 242);
                pdf.text(this.certificate.placeOfIssue, width / 2 - 15, 242);

                pdf.setFontSize(12)

                //obtain marks
                pdf.text(this.adca.marks.computerFundamentals, width / 1.2, 143);
                pdf.text(this.adca.marks.window, width / 1.2, 151.1);
                pdf.text(this.adca.marks.ms_office, width / 1.2, 160);
                pdf.text(this.adca.marks.tally, width / 1.2, 177);
                pdf.text(this.adca.marks.typing, width / 1.2, 185);

                //total marksheet
                pdf.text(this.adca.totalObtainedMarks, width / 1.2, 216);

                // calculation part
                pdf.text(this.adca.totalPercentage, 50, 225);
                pdf.text(this.adca.grade, width / 1.86, 225);
                pdf.text(this.adca.division, width / 1.2, 225);



                // pdf.setFont("normal")
                pdf.setFontSize(10)
                // pdf.setFontStyle('bold');

                const certificateInfo = `Name: ${this.certificate.UserName}\n Issue Date: ${this.certificate.dateOfIssue}\n Certificate ID: ${this.CertificateId}`;

                const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
                    scale: 2.4,
                });
                this.imageDate = qrCodeImageUrl;

                pdf.addImage(this.imageDate, 11, 238);

                pdf.save(this.certificate.UserName + "ADCA marksheet.pdf");
            } catch (e) {

            } finally {
                this.isDownloading = false;
            }
        },

        async DownloadTypingMarkSheet() {
            var pdf = new jsPDF({ orientation: "0" });

            const logo = require("../assets/typing_marksheet.jpg");
            // const font = require("../assets/lum.ttf");


            var imgLogo = new Image();
            imgLogo.src = logo;

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            var Uname = this.certificate.UserName.toUpperCase();
            pdf.setFontSize(10)
            pdf.addImage(imgLogo, "PNG", 0, 0, width, height);
            pdf.setFontSize(10)
            pdf.text("Sl. NO. STM " + this.typing.slNo, 10, 8);
            pdf.setFontSize(12)
            pdf.text(this.certificate.CertificateId, width / 2 + 5, 71);
            pdf.text(Uname, width / 2 + 5, 80,);
            pdf.text("Computer Based Typing", width / 2 + 5, 89.5)
            pdf.text(this.typing.duration, width / 2 + 5, 98.5);
            pdf.text("Sashiv Technology " + this.certificate.placeOfIssue, width / 2 + 5, 108);
            pdf.text(this.certificate.placeOfIssue, 50, 195.5);
            pdf.text(this.certificate.dateOfIssue, 50, 209);

            pdf.setFontSize(12)

            //obtain marks
            pdf.text(this.typing.speedInEnglish, width / 2, 134.5);
            pdf.text(this.typing.speedInHindi, width / 2, 151.5);

            ///bug fixed

            pdf.text(this.typing.marks.hindi, width / 1.2 + 5, 134.5);
            pdf.text(this.typing.marks.english, width / 1.2 + 5, 151.5);

            pdf.text(this.typing.totalObtainedMarks, width / 1.2 + 5, 172);

            // calculation part




            // pdf.setFont("normal")
            pdf.setFontSize(10)
            // pdf.setFontStyle('bold');

            const certificateInfo = `Name: ${this.certificate.UserName}\n Issue Date: ${this.certificate.dateOfIssue}\n Certificate ID: ${this.CertificateId}`;

            const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
                scale: 2.4,
            });
            this.imageDate = qrCodeImageUrl;

            pdf.addImage(this.imageDate, 11, 223);

            pdf.save(this.certificate.UserName + " typing.pdf");
        },

        async DownloadTallyMarkSheet() {
            var pdf = new jsPDF({ orientation: "0" });

            const logo = require("../assets/tally_certificate.jpg");
            // const font = require("../assets/lum.ttf");


            var imgLogo = new Image();
            imgLogo.src = logo;

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            var Uname = this.certificate.UserName.toUpperCase();
            pdf.setFontSize(10)
            pdf.addImage(imgLogo, "PNG", 0, 0, width, height);
            pdf.setFontSize(10)
            pdf.text("Sl NO. STM" + this.tally.slNo, 10, 8);
            pdf.setFontSize(12)
            pdf.text(this.certificate.CertificateId, width / 2 + 5, 71);
            pdf.text(Uname, width / 2 + 5, 80,);
            pdf.text("Tally", width / 2 + 5, 89.5)
            pdf.text(this.tally.duration, width / 2 + 5, 98.5);
            pdf.text("Sashiv Technology " + this.certificate.placeOfIssue, width / 2 + 5, 108);
            pdf.text(this.certificate.placeOfIssue, width / 2 + 26, 242);
            pdf.text(this.certificate.dateOfIssue, width / 2 - 15, 242);

            pdf.setFontSize(12)

            //obtain marks
            pdf.text(this.tally.marks.foundationOfAccounting, width / 1.2, 143);
            pdf.text(this.tally.marks.tallyWork, width / 1.2, 151);
            pdf.text(this.tally.marks.advanceTallyPrimeWithGST, width / 1.2, 159.5);
            pdf.text(this.tally.marks.computerLab, width / 1.2, 168);
            pdf.text(this.tally.marks.projectAndViva, width / 1.2, 176);


            pdf.text(this.tally.totalObtainedMarks, width / 1.2, 215.5);

            // calculation part
            pdf.text(this.tally.totalPercentage, 50, 225);
            pdf.text(this.tally.grade, width / 1.86, 225);
            pdf.text(this.tally.division, width / 1.2, 225);



            // pdf.setFont("normal")
            pdf.setFontSize(10)
            // pdf.setFontStyle('bold');

            const certificateInfo = `Name: ${this.certificate.UserName}\n Issue Date: ${this.certificate.dateOfIssue}\n Certificate ID: ${this.CertificateId}`;

            const qrCodeImageUrl = await QRCode.toDataURL(certificateInfo, {
                scale: 2.4,
            });
            this.imageDate = qrCodeImageUrl;

            pdf.addImage(this.imageDate, 11, 238);

            pdf.save(this.certificate.UserName + " tally.pdf");
        },



        calculateGradeAndPercentage(subjectData) {
            const totalMarksPerSubject = 100; // Assuming total marks for each subject is 100

            let totalObtainedMarks = 0;

            for (const subject in subjectData) {
                if (subjectData[subject] !== "") {
                    const obtainedMarks = parseFloat(subjectData[subject]);
                    totalObtainedMarks += obtainedMarks;
                }
            }
            if (!isNaN(totalObtainedMarks)) {
                const overallPercentage = ((totalObtainedMarks / (Object.keys(subjectData).length * totalMarksPerSubject)) * 100).toFixed(2); // Convert to string with 2 decimal places

                let grade = '';
                let division = '';

                // Generate a grade based on the overall percentage
                if (overallPercentage >= 75) {
                    grade = 'A';
                    division = "First"
                } else if (overallPercentage >= 51) {
                    grade = 'B';
                    division = "First"
                } else if (overallPercentage >= 30) {
                    grade = 'C';
                    division = "Second"
                } else {
                    grade = 'Try Again';
                    division = "Fail"
                }

                return { grade, division, totalPercentage: overallPercentage + '%', totalObtainedMarks: totalObtainedMarks.toString() };
            } else {
                return { grade: 'Error', division: 'Error', totalPercentage: 'Error', totalObtainedMarks: "" };
            }
        },





        verifyCertificate() {
            this.isShow = false;
            this.isRed = false;
            this.showDownloadMarkSheetButton = false;
            this.showDownloadTallyButton = false;
            this.showDownloadTypingButton = false;
            this.isDownloading = true


            if (!this.CertificateId.trim()) {
                this.isRed = true;
                alert("Certificate ID cannot be empty");
                return; // Exit the method if CertificateId is empty
            }
            axios
                .get(
                    `https://websashiv-default-rtdb.firebaseio.com/Certificate/${this.CertificateId.trim()}.json`
                )
                .then((response) => {
                    if (response.data) {
                        this.certificate.UserName = response.data.name;
                        this.certificate.fatherName = response.data.fatherName;
                        this.certificate.CertificateId = response.data.id;
                        this.certificate.course = response.data.course;
                        this.certificate.duration = response.data.duration;
                        this.certificate.grade = response.data.grade
                        this.certificate.placeOfIssue = response.data.placeOfIssue;
                        this.certificate.dateOfIssue = response.data.issueDate;
                        this.certificate.slNo = response.data.slNo
                        this.certificate.dob = response.data.dob


                        this.isShow = true;
                    } else {
                        this.isRed = true;
                        alert("Invalid Certificate ID: Please Enter a Valid Certificate ID");
                    }
                })
                .finally(() => {
                    if (this.isShow) {
                        axios
                            .get(
                                `https://websashiv-default-rtdb.firebaseio.com/Marksheet/${this.CertificateId.trim()}.json`
                            )
                            .then((response) => {
                                if (response.data) {
                                    this.adca.duration = response.data.duration;
                                    this.adca.marks.computerFundamentals = response.data.computerFoundation;
                                    this.adca.marks.window = response.data.window;
                                    this.adca.marks.ms_office = response.data.msOffice;
                                    this.adca.marks.tally = response.data.tally;
                                    this.adca.marks.typing = response.data.typing;
                                    this.isShow = true;
                                    this.adca.slNo = response.data.slNo
                                    this.showDownloadMarkSheetButton = true;


                                    const adcaResult = this.calculateGradeAndPercentage(this.adca.marks);
                                    this.adca.grade = adcaResult.grade;
                                    this.adca.division = adcaResult.division;
                                    this.adca.totalPercentage = adcaResult.totalPercentage;
                                    this.adca.totalObtainedMarks = adcaResult.totalObtainedMarks
                                }
                            })
                            .finally(() => {

                                if (this.isShow) {
                                    axios
                                        .get(
                                            `https://websashiv-default-rtdb.firebaseio.com/Tally/${this.CertificateId.trim()}.json`
                                        )
                                        .then((response) => {
                                            if (response.data) {
                                                this.tally.duration = response.data.duration;
                                                this.tally.marks.foundationOfAccounting = response.data.foundationOfAccounting;
                                                this.tally.marks.tallyWork = response.data.tallyWork;
                                                this.tally.marks.advanceTallyPrimeWithGST = response.data.advanceTallyPrimeWithGST;
                                                this.tally.marks.computerLab = response.data.computerLab;
                                                this.tally.marks.projectAndViva = response.data.projectAndViva;
                                                this.tally.slNo = response.data.slNo
                                                this.showDownloadTallyButton = true;


                                                const tallyResult = this.calculateGradeAndPercentage(this.tally.marks);
                                                this.tally.grade = tallyResult.grade;
                                                this.tally.division = tallyResult.division;
                                                this.tally.totalPercentage = tallyResult.totalPercentage;
                                                this.tally.totalObtainedMarks = tallyResult.totalObtainedMarks
                                            }
                                        })
                                        .finally(() => {




                                            if (this.isShow) {
                                                axios
                                                    .get(
                                                        `https://websashiv-default-rtdb.firebaseio.com/Typing/${this.CertificateId.trim()}.json`
                                                    )
                                                    .then((response) => {
                                                        if (response.data) {
                                                            this.typing.duration = response.data.duration;
                                                            this.typing.marks.hindi = response.data.hindi;
                                                            this.typing.marks.english = response.data.english;
                                                            this.typing.speedInHindi = response.data.speedInHindi
                                                            this.typing.speedInEnglish = response.data.speedInEnglish
                                                            this.typing.slNo = response.data.slNo
                                                            this.showDownloadTypingButton = true;

                                                            const typingResult = this.calculateGradeAndPercentage(this.typing.marks);
                                                            this.typing.grade = typingResult.grade;
                                                            this.typing.division = typingResult.division;
                                                            this.typing.totalPercentage = typingResult.totalPercentage;
                                                            this.typing.totalObtainedMarks = typingResult.totalObtainedMarks
                                                        }
                                                    })
                                                    .finally(() => {
                                                        if (this.isShow) {
                                                            this.isDownloading = false


                                                        }
                                                    });

                                            }
                                        });
                                }
                            });
                    }
                });
        },

        formatPostData(posts) {
            this.postsData = [];

            for (let key in posts) {
                alert(posts.Name);
                this.postsData.push({ ...posts[key], id: key });
            }
        },
    },

};
</script>

<style scoped></style>
