<template>
  <div>
    <section class="hero">
      <div class="container">
        <CourseDetails />
      </div>
    </section>
  </div>
</template>
<script>
import CourseDetails from "./CourseDetails.vue";

export default {
  name: "CoursePage",
  components: {
  
    CourseDetails,
  },
};
</script>
<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
section.hero {
  margin: 5rem 0;
  display: flex;
  justify-content: center;
}
</style>
