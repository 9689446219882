<template>
  <div>
    <div class="py-16">
      <div class="container mx-auto">
        <h4 class="text-4xl text-center text-light-blue-500 mb-8" data-aos="fade-up">
          Courses
        </h4>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <div v-for="(item, index) in product" :key="item.color" :data-index="index" data-aos="fade-up">
            <div class="bg-white rounded-lg overflow-hidden shadow-lg">
              <div class="overflow-hidden">
                <img :src="item.src" class="w-full transform hover:scale-150 transition-transform duration-1000"
                  alt="Course Image" />
              </div>
              <div class="p-4">
                <h5 class="text-xl font-semibold mb-2">{{ item.title }}</h5>
                <p class="text-gray-800 mb-4">{{ item.description }}</p>
                <a href="#"
                  class="btn bg-light-blue-500 text-white px-4 py-2 rounded-full hover:bg-light-blue-600 transition duration-300">Read
                  More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div></template>
<script>
export default {
  name: "CourseDetails",
  components: {},

  data() {
    return {
      product: [
        {
          title: "App Designing",
          color: "green",
          description:
            "mastering in android app development - using java a beginner can build app with in 5 days",
          src: require("../assets/ApplicationDevelopment.png"),
        },
        {
          title: "Website Designing",
          color: "green",
          description:
            "Complete Course Of fullstack Web development using HTML,CSS,JS,PHP Vue js 3 - #beginner to advance",
          src: require("../assets/webimage.png"),
        },
        {
          title: "DATA STRUCTURES for beginners",
          color: "green",
          description:
            "complete data structure in C, C++, Java beginner to advance",
          src: require("../assets/datastructure.jpg"),
        },
        {
          title: "Networking",
          color: "green",
          description:
            "Types of Networking, Network Media, Basic Requirement for Networking",
          src: require("../assets/networking.jpg"),
        },

        {
          title: "Hardware",
          description:
            "Introduction to Computers, LAPTOP, Desktop, Motherboard, RAM (Memory)",
          color: "green",
          src: require("../assets/harware.jpg"),
        },
        {
          title: "Typing",
          description:
            "Touch typing in english as well as hindi with in one months",
          color: "green",
          src: require("../assets/typing.jpg"),
        },
        {
          title: "DCA",
          description: "Computer Fundamental, MS Paint, Notepad, Wordpad",
          color: "green",
          src: require("../assets/networking.jpg"),
        },

        {
          title: "ADCA",
          description:
            "DCA, Tally Practice at levels best suited for you  with addition free notes ",
          color: "green",
          src: require("../assets/adca.jpg"),
        },

        {
          title: "DTP",
          description:
            "Computer Fundamentals, Windows O/S, Photoshop, Typing Free",
          color: "green",
          src: require("../assets/networking.jpg"),
        },
        {
          title: "TALLY",
          description:
            "Introduction of Tally, Company Details & Registration, Basics of Account, Balance Sheet",
          color: "green",
          src: require("../assets/tally.png"),
        },
        {
          title: "PhotoShop",
          description:
            "Making Passport size photo join photo full photo doccument editing etc",
          color: "green",
          src: require("../assets/photoshop.jpg"),
        },
        {
          title: "Cyber Security",
          description:
            "ethical hacking SQL injection Ransomware and the top most cyber security skills",
          color: "green",
          src: require("../assets/cybersecurity.jpg"),
        },
      ],
    };
  },
};
</script>
<style scoped>
</style>