<template>
    <div>
        <Hero @scroll-to-next-section="scrollToNextSection" />
        <Hero2 @scroll-to-next-section="scrollToNextSection" />
        <Hero3 @scroll-to-next-section="scrollToNextSection" />
        <course-details @scroll-to-next-section="scrollToNextSection" />
    </div>
</template>

<script>
import CourseDetails from './CourseDetails.vue';
import Hero from './Hero.vue';
import Hero2 from './Hero2.vue';
import Hero3 from './Hero3.vue';

export default {
    name: "Home",
    components: {
        Hero,
        Hero2,
        Hero3,
        CourseDetails,
    },
    methods: {
        scrollToNextSection() {
            // Find the next section to scroll to based on the current scroll position
            const sections = ["Hero", "Hero2", "Hero3", "CourseDetails"];
            const currentSectionIndex = sections.findIndex(
                (section) => section === this.activeSection
            );

            if (currentSectionIndex < sections.length - 1) {
                // Scroll to the next section
                const nextSection = sections[currentSectionIndex + 1];
                const nextSectionElement = document.getElementById(nextSection);
                if (nextSectionElement) {
                    nextSectionElement.scrollIntoView({ behavior: "smooth" });
                }
            }
        },
    },
    data() {
        return {
            activeSection: "Hero", // Initialize with the first section
        };
    },
    created() {
        // Listen to scroll events to determine the active section
        window.addEventListener("scroll", this.determineActiveSection);
    },
    destroyed() {
        // Remove the scroll event listener when the component is destroyed
        window.removeEventListener("scroll", this.determineActiveSection);
    },
    methods: {
        determineActiveSection() {
            // Determine the currently active section based on the scroll position
            const scrollPosition = window.scrollY;
            const sectionOffsets = {
                Hero: 0, // Adjust the offset as needed
                Hero2: 0, // Adjust the offset as needed
                Hero3: 0, // Adjust the offset as needed
                CourseDetails: 0, // Adjust the offset as needed
            };

            for (const section in sectionOffsets) {
                const offset = sectionOffsets[section];
                const sectionElement = document.getElementById(section);

                if (sectionElement) {
                    const sectionTop = sectionElement.offsetTop + offset;
                    const sectionBottom = sectionTop + sectionElement.clientHeight;

                    if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
                        // Set the active section based on the scroll position
                        this.activeSection = section;
                        break;
                    }
                }
            }
        },
    },
};
</script>
