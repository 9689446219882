<template>
  <div>
    <header class="bg-secondary shadow" style="position: fixed; top: 0; width: 100%; z-index: 999">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ">
        <div class="flex justify-between items-center h-16">
          <div class="flex-shrink-0 flex items-center">
            <img class="h-8 w-auto" src="../assets/logo.png" alt="Logo" />
            <h1 class="ml-3 text-lg font-bold leading-6 text-white">
              Sashiv Technology
            </h1>
          </div>
          <div class="flex items-center">
            <div class="hidden sm:block">
              <nav class="flex">
                <router-link to="/" class="px-3 py-2 text-white font-medium hover:font-bold">Home</router-link>
                <router-link to="/CoursePage"
                  class="px-3 py-2 text-white font-medium hover:font-bold">Course</router-link>
                <router-link to="/Gallery" class="px-3 py-2 text-white font-medium hover:font-bold">Gallery
                  </router-link>
                <router-link to="/Certificate" class="px-3 py-2 text-white font-medium hover:font-bold">Verify Certificate
                  </router-link>
                     <router-link to="/contact" class="px-3 py-2 text-white font-medium hover:font-bold">Contact us
                    </router-link>
              </nav>
            </div>
            <div class="-mr-2 flex items-center sm:hidden">
              <button @click="isMenuOpen = !isMenuOpen" type="button"
                class="bg-gray-200 p-2 rounded-md text-gray-600 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500"
                aria-controls="mobile-menu" aria-expanded="false">
                <span class="sr-only">Open main menu</span>
                <!-- Icon when menu is closed. -->
                <!-- Menu open: "hidden", Menu closed: "block" -->
                <svg :class="{ hidden: isMenuOpen, block: !isMenuOpen }" class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                </svg>
                <!-- Icon when menu is open. -->
                <!-- Menu open: "block", Menu closed: "hidden" -->
                <svg :class="{ block: isMenuOpen, hidden: !isMenuOpen }" class="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
                  aria-hidden="true">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div :class="{ block: isMenuOpen, hidden: !isMenuOpen }" class="sm:hidden" id="mobile-menu">
        <nav class="px-2 pt-2 pb-3 space-y-1">
          <router-link to="/" class="block px-3 py-2 text-base font-medium text-white hover:font-bold">Home</router-link>
          <router-link to="/Course" class="block px-3 py-2 text-base font-medium text-white hover:font-bold">Course</router-link>
          <router-link to="/Gallery" class="block px-3 py-2 text-base font-medium text-white hover:font-bold">Gallery</router-link>
          <router-link to="/Certificate" class="block px-3 py-2 text-base font-medium text-white hover:font-bold">Verify Certificate</router-link>
          <router-link to="/Contact" class="block px-3 py-2 text-base font-medium text-white hover:font-bold">Contact us</router-link>
        </nav>
      </div>
    </header>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isMenuOpen: false,
    };
  },
};
</script>