<template>
  <div class="flex justify-center">
    <section class="hero bg-gradient-to-r container text-black py-12">
        <div class="text-5xl font-bold mb-4">Website Development</div>
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
       
          <div class="md:mt-3" data-aos="fade-right">
          
            <div class="text-hero-regular text-gray-800 m-20 text-start">
              By the end of this course, you will be able to design and develop a complete website and describe the
              functionality of the world wide web. You will create dynamic web pages using a combination of HTML, CSS,
              JavaScript, and the Vue.js 3 framework.
                 <h2 class="text-2xl font-semibold mt-4">COURSE OUTLINE OF BEST WEBSITE DEVELOPMENT COURSE</h2>
              <div class="text-gray-700 mt-4 ml-6">
                <ul class="list-disc">
                  <li>Basics Of Web Development</li>
                  <li>How a website works?</li>
                  <li>Backend | Front end | Database</li>
                  <li>HTML | CSS | JS | Vue.js 3 | PHP</li>
                  <li>Pillars of web development</li>
                </ul>
              </div>
           
            </div>
               <button href="#"
                  class="btn bg-primary px-6 py-2 hover:bg-secondary text-white rounded-full mt-4 inline-block transition duration-300 ease-in-out transform hover:scale-105">
                  Continue Reading
                </button>
          </div>
          <div class="md:pt-8" data-aos="fade-left">
            <div class="imageDiv mx-auto flex justify-end">
              <img src="../assets/web.svg" class="shadow-lg w-[600px] " alt="Website Development" />
            </div>
          </div>
        </div>
      </div>
    </section>
</div></template>
<script>
export default {
  name: "Hero",

  data() {
    return {};
  },
};
</script>
<style scoped>
</style>