<template>
  <div class="bg-gray-100">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="pt-12 pb-16 md:pt-20 md:pb-24 md:flex md:items-center">

        <div class="md:w-1/2 mt-8 md:mt-0 ">
          <h1 class="text-3xl min-h-[100px] md:text-5xl font-bold tracking-tight text-gray-900 text-center md:text-left"
            v-text="typedText"></h1>

          <p class="mt-5 md:text-xl text-gray-600 text-center md:text-left"> Sashiv Technology has a highly influential
            and active network of
            alumni across the globe, education at Sashiv Technology empowers
            the students with a critical thinking led scientific approach to
            solve problems that challenge humanity. The faculty and alumni
            of Sashiv Technology have made a huge impact in all sectors of
            society, both in India and abroad.</p>
          <div class="mt-10 md:mt-16 flex items-center justify-center md:justify-start">
            <router-link to="learnMore"
              class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded ">Continue Reading</router-link>

          </div>
        </div>
        <div class="md:w-1/2 mt-3">
          <img class="w-full h-auto md:max-w-md mx-auto md:mx-0 md:ml-auto " src="../assets/logo.png"
            alt="CSP Photo & Aadhaar Print Logo">
        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  mounted() {
    this.startTypingEffect();
  },

  methods: {
   startTypingEffect() {
      if (this.currentIndex <= this.fullText.length) {
        this.typedText = this.fullText.slice(0, this.currentIndex);
        this.currentIndex++;
        setTimeout(this.startTypingEffect, 100); // Adjust the delay as needed
      } else {
        setTimeout(() => {
          this.typedText = "";
          this.currentIndex = 0;
          this.startTypingEffect();
        }, 500); // Adjust the delay before restarting the typing effect
      }
    },
  },
  data() {
    return {
      typedText: "",
      fullText: "Welcome to Sashiv Technology", // The full text you want to display
      currentIndex: 0,
    };
  },


}
</script>



 