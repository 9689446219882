<template>
  <div class="bg-gray-100 min-h-screen flex flex-col justify-center items-center">
    <div class="max-w-md w-full mx-auto p-8 bg-white rounded shadow">
      <h2 class="text-2xl font-bold mb-6">Contact Us</h2>
      <form @submit.prevent="onAddPost"  ref="userForm"> <!-- Use onAddPost method here -->
        <div class="mb-4">
          <label for="name" class="flex mb-2">Name</label>
          <input type="text" id="name" v-model="UserDetails.userName"
            class="w-full border border-gray-300 px-4 py-2 rounded" required />
        </div>
        <div class="mb-4">
          <label for="email" class="flex mb-2">Email </label>
          <input type="email" id="email" v-model="UserDetails.userEmail"
            class="w-full border border-gray-300 px-4 py-2 rounded" required />
        </div>
        <div class="mb-4">
          <label for="message" class="flex mb-2">Message</label>
          <textarea id="message" v-model="UserDetails.userMessage" class="w-full border border-gray-300 px-4 py-2 rounded"
            required></textarea>
        </div>
        <div class="text-center">
          <button type="submit" class="bg-primary hover:bg-secondary text-white font-bold py-2 px-4 rounded">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div></template>

<script>
import Header from "./Header.vue";
import axios from "axios";


export default {
  name: "Contact",
  components: {
    Header,
  },
  data() {
    return {
      UserDetails: {
        userName: "",
        userEmail: "",
        userMessage: "",
      },
    };
  },
  methods: {
    onAddPost() {
      axios
        .post(
          `https://websashiv-default-rtdb.firebaseio.com/Queries.json`,
          this.UserDetails
        )
        .then((response) => {
        
            alert("Thanks for yor interest our team will get you soon ");
            this.$refs.userForm.reset();
      
        });
    },
  },
};
</script>
<style scoped>

</style>