<template>
  <div class="flex justify-center">
    <section class="hero bg-gradient-to-r container text-black py-12">

      <div class=" text-5xl py-2">Android App Development</div>

      <div class="w-full flex flex-col lg:flex-row container justify-center">

        <div class="w-full lg:w-1/2  flex justify-center">
          <div class="imageDiv">
            <img class="w-[600px] rounded-lg shadow-lg" src="../assets/app.svg" alt="Android App Development" />
          </div>
        </div>
        <div class="w-full lg:w-1/2  rounded-lg p-6">

          <div class="text-hero-regular text-start w-full p-10 items-center text-gray-800">
            <p class="text-xl">
              This Course Will Introduce You to Android App development, the best android development course where
              students
              learn the process by which new mobile applications are developed for the Android OS used in mobile phones.
            </p>
            <h2 class="text-2xl font-semibold mt-4">COURSE OUTLINE OF BEST ANDROID DEVELOPMENT COURSE</h2>
            <ul class="list-disc pl-4 mt-2">
              <li>Basics Of android application</li>
              <li>UI (User Interface Design)</li>
              <li>Java | XML</li>
              <li>Pillars of Android</li>
            </ul>
          </div>
          <button @click="scrollToNextSection"
            class="bg-primary hover:bg-secondary px-6 py-2 text-white rounded-full mt-4 inline-block transition duration-300 ease-in-out transform hover:scale-105">
            Continue Reading
          </button>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Hero",

  data() {
    return {};
  },
  methods: {
    scrollToNextSection() {
      // Emit a custom event to notify the parent component (Home.vue) to scroll to the next section.
      this.$emit("scroll-to-next-section");
    },
  }
};
</script>
<style scoped></style>