
import Home from "./components/Home.vue"

import { createRouter, createWebHistory } from 'vue-router'

import Contact from "./components/Contact.vue";
import Gallery from "./components/Gallery.vue";
import CoursePage from "./components/CoursePage.vue";
import LearnMore from "./components/LearnMore.vue";
import NewCertificate from "./components/Verify_NewCertificate.vue";


const routes = [
  {
    name: "Home",
    component: Home,
    path: "/",
    meta: {
      title: "Home",
    },
  },

  {
    name: "Contact",
    component: Contact,
    path: "/Contact",
    meta: {
      title: "Contact",
    },
  },

  {
    name: "Gallery",
    component: Gallery,
    path: "/Gallery",
    meta: {
      title: "Gallery",
    },
  },

  {
    name: "NewCertificate",
    component: NewCertificate,
    path: "/Certificate",
    meta: {
      title: "Certificate",
    },
  },
  {
    name: "CoursePage",
    component: CoursePage,
    path: "/CoursePage",
    meta: {
      title: "Course Details",
    },
  },
  {
    name: "LearnMore",
    component: LearnMore,
    path: "/LearnMore",
    meta: {
      title: "LearnMore",
    },
  },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next()
});

export default router;