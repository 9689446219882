<template>
    <div class="m-20 flex justify-center ">
        <section class="bg-gray-100 py-12">
            <div class="container mx-auto px-5">
                <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                    <div v-for="(item, index) in Images" :key="index"
                        class="relative overflow-hidden rounded-lg shadow-md transition-transform transform hover:scale-105">
                        <img :src="item.src" alt="Gallery Item" class="w-full h-auto" />
                        <div
                            class="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 hover:opacity-100 transition-opacity">
                            <button @click="OpenDialog(item.src)"
                                class="text-white font-semibold text-lg px-4 py-2 rounded-full bg-primary hover:bg-primary-dark transition-colors">
                                View Image
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <!-- Popup Dialog -->
        <div v-if="Show" class="fixed inset-0 flex items-center justify-center bg-white p-4 rounded-lg shadow-lg z-50 m-20  popup-container">
         
                <button @click="PopUp" class="absolute top-2 right-2 text-gray-500 hover:text-red-500 text-xl">
                    &times;
                </button>
                <div class="popup-content" style="max-height: 70vh; overflow-y: auto; ">
                    <img :src="ActiveImage" alt="Popup Image" class="w-full h-auto" />
                </div>
       
        </div>
    </div>
</template>

<script>
import Header from "./Header.vue";


export default {
    name: "Gallery",
    components: {
        Header,

    },

    props: {},
    data() {
        return {
            Show: false,
            ActiveImage: require("../assets/img1.jpg"),
            Images: [
              {
                src: require("../assets/img1.jpg"),
              },
              {
                src: require("../assets/img2.jpg"),
              },
              {
                src: require("../assets/img3.jpg"),
              },
              {
                src: require("../assets/img4.jpg"),
              },
              {
                src: require("../assets/img5.jpg"),
              },
              {
                src: require("../assets/img6.jpg"),
              },
              {
                src: require("../assets/img7.jpg"),
              },
              {
                src: require("../assets/img8.jpg"),
              },
              {
                src: require("../assets/new1.jpg"),
              },
               {
                src: require("../assets/new2.jpg"),
              },
                 {
                src: require("../assets/new3.jpg"),
              },
               {
                src: require("../assets/new4.jpg"),
              },
               {
                src: require("../assets/new5.jpg"),
              },
               {
                src: require("../assets/new6.jpg"),
              },
               {
                src: require("../assets/new7.jpg"),
              },
               {
                src: require("../assets/new8.jpg"),
              },
               {
                src: require("../assets/new9.jpg"),
              },
               {
                src: require("../assets/new10.jpg"),
              },
               {
                src: require("../assets/new11.jpg"),
              },
               {
                src: require("../assets/new12.jpg"),
              },
               {
                src: require("../assets/new13.jpg"),
              },
               {
                src: require("../assets/new14.jpg"),
              },
               {
                src: require("../assets/new15.jpg"),
              },
               {
                src: require("../assets/new16.jpg"),
              },
               {
                src: require("../assets/new17.jpg"),
              },
               {
                src: require("../assets/new18.jpg"),
              },
               {
                src: require("../assets/new19.jpg"),
              },


            ],
        };
    },
    mounted() { },
    methods: {
        PopUp() {
            this.Show = false;
        },
        OpenDialog(srcs) {

            this.ActiveImage = srcs;
            this.Show = true;

        },

    },
};
</script>
<style scoped>
.popup-container {
    backdrop-filter: blur(10px); /* Adjust the blur value as needed */
    background-color: rgba(255, 255, 255, 0.7); /* Adjust the background color and opacity as needed */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 50;
}

.popup-content {
    max-height: 70vh;
    overflow-y: auto;
}

/* Style for the popup container */
</style>