<template>
  <page-loader />

  <Header  />
  <router-view></router-view>
  <Footer  />
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import PageLoader from "./components/PageLoader.vue";


export default {
  name: 'App',
  components: {
    Header,
    Footer,
    PageLoader
  },



}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
</style>
