<template>
  <div>
    <section class="hero bg-gray-100 py-16">
      <div class="container mx-auto">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          <div class="md:col-span-1 md:mt-3" data-aos="fade-right">
            <div class="imageDiv">
              <img src="../assets/software.svg" class="rounded-lg shadow-lg" alt="Sashiv Technology" />
            </div>
          </div>
          <div class="md:col-span-1 copy" data-aos="fade-left">
            <h1 class="text-5xl mb-8 text-black font-bold text-center md:text-left">
              Sashiv Technology
            </h1>
            <p class="text-hero-regular text-lg text-gray-700">
              SASHIV Technology, an Indian leader in Skills and Talent Development, offers multi-disciplinary learning and
              training delivery solutions to corporations, institutions, and individuals worldwide. We have three main
              lines of business:
            </p>
            <ul class="list-disc pl-4 mt-4 text-gray-700">
              <li class="mb-2">SASHIV’s Corporate Learning Group (SCLG) - Dedicated to helping customers increase the
                business value of learning and development (L&D).</li>
              <li class="mb-2">SASHIV’s Skills and Careers Group (SSNC) - Delivers a diverse range of learning and talent
                development programs to millions of individual and corporate learners.</li>
              <li class="mb-2">SASHIV TV - A digital learning initiative that brings skilling from SASHIV classrooms to
                every digitally connected Indian, for free.</li>
            </ul>
            <p class="text-hero-regular text-lg mt-4 text-gray-700">
              As the Most Trusted Training Brand in India for the 3rd year in a row, SASHIV’s learning and talent
              development solutions continue to receive widespread recognition globally. We have been named among the Top
              Training Outsourcing Companies for the past years by Training Industry.
            </p>
            <p class="text-hero-regular text-lg mt-4 text-gray-700">
              SASHIV is a leading Skills and Talent Development Corporation that is building a manpower pool for global
              industry requirements. The company, which was set up in 2014 to help the nascent IT industry overcome its
              human resource challenges, today ranks among the world’s leading training companies owing to its vast, yet
              comprehensive array of talent development programs. SASHIV offers training and development solutions to
              Individuals, Enterprises, and Institutions.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "LearnMore",
};
</script>

<style scoped>
.imageDiv img {
  max-width: 100%;
  height: auto;
}

@media (min-width: 768px) {
  .copy {
    padding: 2rem;
  }
}</style>
